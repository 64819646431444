import React, { FC } from 'react';
import DefaultLayout from '@/layouts/default';
import TopBar from '@/components/top-bar';
import LogoMark from '@/components/logo-mark';

const Discount: FC = () => (
  <DefaultLayout>
    <TopBar>
      <LogoMark />
    </TopBar>
    <h1>Discount Programs</h1>
    <p>
      It’s important that developers in all circumstances have access to achieve
      their Laravel Certification goals. Multiple discount programs are
      provided. If you feel that the discount options outlined below don’t
      adequately acknowledge your circumstances then please contact us for
    </p>
    <h2>Economy Discount</h2>
    <p>
      Developers and organizations located in qualifying countries can receive
      an economy discount. We’ll walk candidates through an identity and
      geographic verification process before the exam takes place.
    </p>
  </DefaultLayout>
);

export default Discount;
